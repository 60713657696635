<template>

    <section id="accueil">
      <div id="box-cover">
        <div id="img-bx">
          
        </div>
        <div id="logo-bx">
            <img src="../img/logo.jpg" alt="GMR Déneigement - GROUPE MARCK & ROUSSEAU DÉNEIGEMENT & AMÉNAGEMENT">
        </div>
        <div>
          <h3>GROUPE MARCK & <br>ROUSSEAU</h3>
          <p>DÉNEIGEMENT & AMÉNAGEMENT ÉTÉ & HIVER</p>
        </div>
        <a href="#contact">
          NOUS CONTACTER
        </a>
      </div>
      <div id="ac-pr-box">
        <h2>Déneigement à Blainville & Repentigny</h2>
        <p>GMR Déneigement s'occupe du déneigement résidentiel et des copropriétés sur la Rive-Sud et la Rive-Nord de Repentigny et Blainville, Québec. Avec une vingtaine de tracteurs robustes, nous sommes prêts à affronter toutes les tempêtes hivernales, garantissant un service rapide et efficace.</p>
      </div>
      <div id="ac-pqn">
      <h2 >Pourquoi nous choisir</h2>
      <div id="ac-box">
          <div id="ac-box-i1">
            <h3>Suivi en temps réel
            </h3>
            <p>
Suivez le déneigement en direct grâce au GPS de nos tracteurs. Recevez un SMS environ 30 minutes avant l'arrivée du déneigeur chez vous !</p>
<h3>Équipements modernes</h3>
                <p>Avec plus de 15 tracteurs-souffleurs modernes, GMR Déneigement offre un service rapide et efficace, sans laisser de neige derrière, tout en protégeant les pavés grâce à des bases en téflon.</p>
             
          </div>
          <div id="ac-box-i2">
          
          </div>
          

            <div id="ac-box-i3">
              
            </div>
            <div id="ac-box-i4">
            <h3>Paiement à la fin de la saison !</h3>
              <p>Le paiement est requis uniquement à la fin de la saison, avec un contrat signé. Aucune demande ou enquête de crédit n’est nécessaire.             </p>
              </div>
      </div>
    </div>
  </section>
</template>

<script>

</script>

<style scoped>
#ac-pr-box {
  padding: 5px;
  background-color: #f0f0f0;
}

#ac-pqn {
  position: relative;
  z-index: -1;
}
#ac-pr-box > p:nth-child(2) {
  max-width: 930px;
  margin: 20px auto;
  font-size: 22px;
}
#ac-pr-box > h2:nth-child(1) {
  /*! margin-top: 45px; */width: fit-content;
  margin: 32px auto;
}

#ac-pqn{
display: flex;
flex-direction: column;
align-items: center;
justify-content: center;
padding: 22px 0;
}
#ac-pqn h2 {
  margin: 32px ;
}
#ac-box-i1 > h3, #ac-box-i4 > h3 {
font-size: 25px;
width: 100%;
padding: 2px 0;
font-family: "DynaPuff", system-ui;

}
#box-cover{
  position: relative;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: white;
  text-align: center;
  filter: brightness(0.9);
  position: relative;
}

#img-bx{
  background-image: url('../img/CV.jpg');
  background-size: cover;
  background-position: center;
  position: absolute;
  filter: brightness(0.9);
  width: 100%;
  height: 100%;
  z-index: -1;
}
#box-cover > *{
  padding: 25px;
}


#box-cover >div  > h3 {
  font-size: 3em;
  font-weight: 700;
}

#box-cover > div >p {
  font-size: 1.5em;
  font-weight: 510;
}

#box-cover  a {
  text-decoration: none;
  color: white;
  background-color: #0188b1;
  padding: 10px 20px;
  border-radius: 5px;
  font-size: 1.2em;
  font-weight: 700;
  margin-top: 20px;
}

#box-cover a:hover {
  background-color: #015f7d;
}


#logo-bx  {
 top: 13%;
  left: 10%;
  overflow: hidden;
  width: 250px;
  height: 250px;
  border-radius: 100%;
  position: absolute;
  margin: 0 auto;
  z-index: 1;
}

#logo-bx img {
  width: 100%;
  object-fit: cover;
  border-radius: 100%;
}



h2 {
  color: #0188b1;

}

#ac-box > div {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  padding: 50px;
}

#ac-box {
  display: grid;
  flex: 0 0 auto;
  gap: 20px;
  grid-auto-rows: minmax(0px, 1fr);
  grid-template-columns: repeat(5, minmax(20px, 1fr));
  grid-template-rows: repeat(3, minmax(0px, 1fr));
  height: 600px;
  justify-content: center;
  max-width: 1000px;
  overflow: visible;
  padding: 0px;
  position: relative;
  width: 100%;
  z-index: 3;
  padding: 5px;

}

#ac-box-i1 

  {
  place-content: flex-start flex-end;
  align-items: flex-start;
  place-self: start;
  border-bottom-left-radius: 60px;
  border-bottom-right-radius: 60px;
  border-top-left-radius: 60px;
  display: flex;
  flex: 0 0 auto;
  flex-flow: column;
  gap: 30px;
  grid-area: auto / auto / span 2 / span 3;
  height: 100%;
  overflow: hidden;
  padding: 60px;
  position: relative;
  width: 100%;
  will-change: var(--framer-will-change-override, transform);
  z-index: 1;
  border: 2px solid #015f7d;
}


#ac-box-i2 {
  background-image: url('../img/deneigement.jpg');
  background-size: cover;

  place-self: start;
  border-bottom-left-radius: 200px;
  border-top-right-radius: 200px;
  flex: 0 0 auto;
  grid-area: auto / auto / span 2 / span 2;
  height: 100%;
  overflow: hidden;
  position: relative;
  width: 100%;
  will-change: var(--framer-will-change-override, transform);
  z-index: 1;
}

#ac-box-i3 {
  background-image: url('../img/deneigement_residentiel.jpg');
  background-size: cover;


  place-self: start;
  border-bottom-right-radius: 200px;
  border-top-left-radius: 200px;
  border-top-right-radius: 200px;
  flex: 0 0 auto;
  grid-column: auto / span 2;
  height: 100%;
  mix-blend-mode: luminosity;
  overflow: hidden;
  position: relative;
  width: 100%;
  will-change: var(--framer-will-change-override, transform);
  z-index: 1;
}

#ac-box-i4 {
  place-content: center;
  align-items: center;
  place-self: start;
  border: 2px solid #015f7d;
  border-radius: 200px;
  display: flex;
  flex: 0 0 auto;
  flex-flow: row;
  gap: 10px;
  grid-column: auto / span 3;
  height: 100%;
  overflow: hidden;
  padding: 20px;
  position: relative;
  width: 100%;
  will-change: var(--framer-will-change-override, transform);
  z-index: 1;

}

@media screen and (max-width: 568px) {
  #logo-bx  {
  top:  5%;
  left: 50%;
  transform: translate(-50%, 0);
  width: 210px;
  height: 210px;
  z-index: -1;

  
}}

@media screen and (max-width: 768px) {
  #logo-bx  {
  top:  7%;
  left: 50%;
  transform: translate(-50%, 0);
  width: 240px;
  height: 240px;
  z-index: -1;

  
}
#box-cover >div  > h3 {
  font-size: 2em;
}

#ac-box {
    align-content: center;
    align-items: center;
    display: flex;
    flex-flow: column;
    height: min-content;
    order: 2;
  }
  #grp-ct > div:nth-child(1) {
  display: none;
}

}

</style>
