<template>
    <nav id="header">
      <h1 id="title">GMR DÉNEIGEMENT</h1>
      <div class="menu-icon" @click="toggleMenu">
        <div class="bar"></div>
        <div class="bar"></div>
        <div class="bar"></div>
      </div>
      <ul :class="{ 'show-menu': isMenuOpen }">
        <li><a href="#accueil">Accueil</a></li>
        <li><a href="#service">Service</a></li>
        <li><a href="#contact">Contact</a></li>
      </ul>
    </nav>
    <body>
      <PresentationComponent />
      <ServiceComponent />
      <ContactComponent />
    </body>
  </template>
  
  <script setup>
  import { ref } from 'vue';
  import PresentationComponent from '../components/PresentationComponent.vue'
  import ServiceComponent from '../components/ServiceComponent.vue'
  import ContactComponent from '../components/ContactComponent.vue';
  
  const isMenuOpen = ref(false);
  
  const toggleMenu = () => {
    isMenuOpen.value = !isMenuOpen.value;
  };
  </script>
  
  <style scoped>
  a {
    text-decoration: none;
  }
  
  #header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 5%;
    background-color: #f0f0f0;
    border-bottom: 2px solid #0188b1;
  }
  
  #header h1 {
    margin: 0;
    font-size: 24px;
    color: #0188b1;
    padding: 12px;
  }
  
  #header > ul {
    list-style: none;
    display: flex;
    margin: 0;
    padding: 0;
  }
  
  #header > ul > li {
    margin-right: 0px;
    font-weight: 700;
    font-size: 18px;
    cursor: pointer;
    height: 100%;
    Padding: 0px;
  }
  
  #header > ul > li:last-child {
    margin-right: 0;
  }
  
  #header li a {
    text-decoration: none;
    padding: 20px 14px;
    color: #0188b1;
    transition: color 0.3s ease, background-color 0.3s ease;
    display: block;
  }
  
  #header li a:hover {
    color: white;
    background-color: #0188b1;
  }
  
  .menu-icon {
    display: none;
    flex-direction: column;
    cursor: pointer;
  }
  
  .bar {
    width: 25px;
    height: 3px;
    background-color: #0188b1;
    margin: 3px 0;
    transition: 0.4s;
  }
  #header{
position: fixed;
width: 100%;
z-index: 2;
}

#title{
  cursor: pointer;
  font-size: 1.3em !important;
}
  @media screen and (max-width: 768px) {
    #header {
      flex-direction: column;
      align-items: flex-start;
    }
  
    #header > ul {
      flex-direction: column;
      width: 100%;
      display: none;
    }
  
    #header > ul.show-menu {
      display: flex;
    }
  
    #header > ul > li {
      margin-right: 0;
      width: 100%;
      text-align: center;
    }
  
    #header li a {
      padding: 10px;
    }
  
    .menu-icon {
      display: flex;
      position: absolute;
      top: 15px;
      right: 15px;
    }
    #title{
  cursor: pointer;
  font-size: 1.2em !important;
}
  }
  </style>